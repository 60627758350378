import { useState, useEffect } from 'react';
import { projectStorage, projectFirestore, timestamp } from '../firebase/config';

const useStorage = (file) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    // references
    const storageRef = projectStorage.ref();
    const collectionRef = projectFirestore.collection('images');

    const checkDuplicateName = async (name) => {
      const fileList = await storageRef.listAll();
      let newFileName = name;
      let index = 1;

      while (await doesFileExist(newFileName, fileList)) {
        const extension = name.split('.').pop();
        const fileNameWithoutExtension = name.replace(`.${extension}`, '');
        newFileName = `${fileNameWithoutExtension}${index}.${extension}`;
        index++;
      }

      return newFileName;
    };

    const doesFileExist = async (fileName, fileList) => {
      const items = await fileList.items;
      for (const item of items) {
        if (item.name === fileName) {
          return true;
        }
      }
      return false;
    };

    const uploadFile = async () => {
      try {
        const uniqueFileName = await checkDuplicateName(file.name);
        const storageChildRef = storageRef.child(uniqueFileName);

        const uploadTask = storageChildRef.put(file);

        uploadTask.on('state_changed', (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          setProgress(percentage);
        }, (err) => {
          setError(err);
        }, async () => {
          const downloadUrl = await storageChildRef.getDownloadURL();
          const createdAt = timestamp();
          await collectionRef.add({ url: downloadUrl, createdAt });
          setUrl(downloadUrl);
        });
      } catch (error) {
        setError(error);
      }
    };

    uploadFile();
  }, [file]);

  return { progress, url, error };
};


export default useStorage;